import { PizzaImg, PokeBowlImg, SteakImg, TacosImg } from 'assets/img';
import Image from 'next/image';

const LandingPageBackground = ({ children }) => (
  <>
    {/* Desktop Background */}
    <div className="hidden md:flex relative md:w-full h-screen bg-background">
      {/* Background Images */}
      <div className="absolute bottom-16 right-0">
        <Image
          src={SteakImg}
          alt="steak-img"
          width="190.05px"
          height="248.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-64 bottom-[-4px]">
        <Image
          src={PokeBowlImg}
          alt="poke-bowl-img"
          width="353.35px"
          height="210.64px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute top-32 right-0">
        <Image
          src={TacosImg}
          alt="tacos-img"
          width="240.05px"
          height="250.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-96 top-0">
        <Image
          src={PizzaImg}
          alt="pizza-img"
          width="298.56px"
          height="259.41px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      {/* Children Component */}
      <div className="w-full h-full relative z-10">{children}</div>
    </div>

    {/* Tablet Background */}
    <div className="hidden md:hidden customMd:flex relative w-full h-screen bg-background">
      {/* Background Images */}
      <div className="absolute bottom-16 right-0">
        <Image
          src={SteakImg}
          alt="steak-img"
          width="200.05px"
          height="248.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-44 bottom-[-4px]">
        <Image
          src={PokeBowlImg}
          alt="poke-bowl-img"
          width="343.35px"
          height="200.64px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute top-44 right-0">
        <Image
          src={TacosImg}
          alt="tacos-img"
          width="240.05px"
          height="240.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-44 top-0">
        <Image
          src={PizzaImg}
          alt="pizza-img"
          width="278.56px"
          height="239.41px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      {/* Children Component */}
      <div className="w-full h-full relative z-10">{children}</div>
    </div>

    {/* Mobile Background */}
    <div className="flex customMd:hidden md:hidden relative w-full h-screen bg-background">
      {/* Background Images */}
      <div className="absolute bottom-24 right-0">
        <Image
          src={SteakImg}
          alt="steak-img"
          width="90.05px"
          height="108.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-16 bottom-[-4px]">
        <Image
          src={PokeBowlImg}
          alt="poke-bowl-img"
          width="170.35px"
          height="90.64px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute top-36 right-0">
        <Image
          src={TacosImg}
          alt="tacos-img"
          width="100.05px"
          height="110.05px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      <div className="absolute right-20 top-0">
        <Image
          src={PizzaImg}
          alt="pizza-img"
          width="128.56px"
          height="109.41px"
          layout="fixed"
          style={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          priority
        />
      </div>

      {/* Children Component */}
      <div className="w-full relative z-10">{children}</div>
    </div>
  </>
);

export default LandingPageBackground;
