import Image from 'next/image';
import classNames from 'classnames';
import AddressPicker from 'components/AddressPicker';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import dynamic from 'next/dynamic';
const DatePicker = dynamic(() => import('components/DatePicker'));
const TimePicker = dynamic(() => import('components/TimePicker'));
import moment from 'moment-timezone';
import { MapIcon } from 'assets/img';

const Error = ({ message = 'This field needs to be filled' }) => (
  <span className="text-attention text-xs mt-1 animate-fade-in-down">{message}</span>
);

const AutoComplete = ({
  addressError,
  handleOnChange,
  router,
  store,
  userLogin,
  address,
  internalAddress,
  setAddress,
}) => (
  <GoogleAutoComplete
    className={classNames(
      'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md text-primary-black w-full',
      {
        'border-attention border': addressError,
      }
    )}
    onChange={handleOnChange}
    placeholder="Enter delivery address"
    onBlur={e => {
      const queryDate = router?.query?.date;

      store.userStore.getAvailableDates({
        address: e?.target?.value,
        month: queryDate ? moment(queryDate).month() + 1 : moment().month() + 1,
        year: queryDate ? moment(queryDate).year() : moment().year(),
        current_date_time: moment().format('YYYY-MM-DD HH:mm'),
      });
    }}
    value={userLogin ? address : internalAddress?.address}
    onPlaceSelected={place => {
      store.userStore?.getAndSetTimezone(place?.formatted_address);

      const _address = place?.address_components?.reduce(
        (seed, { long_name, short_name, types }) => {
          types.forEach(t => {
            if (t == 'administrative_area_level_1') {
              seed[t] = short_name; // Set short_name for province
            } else {
              seed[t] = long_name; // Set long_name for other types
            }
          });
          return seed;
        },
        {}
      );

      setAddress({
        address: place?.formatted_address,
        completeAddress: {
          suite: _address?.subpremise,
          street_number: _address?.street_number,
          street_name: _address?.route,
          city: _address?.locality,
          province: _address?.administrative_area_level_1,
          postal_code: _address?.postal_code,
          destination_code: '',
        },
      });

      store?.userStore?.setAddress(place?.formatted_address, {
        suite: _address?.subpremise,
        street_number: _address?.street_number,
        street_name: _address?.route,
        city: _address?.locality,
        province: _address?.administrative_area_level_1,
        postal_code: _address?.postal_code,
        destination_code: '',
      });
    }}
  />
);

const DateDropdown = ({
  internalAddress,
  date,
  activeRestaurant,
  getAvailableDates,
  getAvailableTimes,
  availableDates,
  isDesktop,
}) => (
  <DatePicker
    fromModal
    isCart={false}
    internalAddress={internalAddress?.address}
    selectedDate={date}
    activeRestaurant={activeRestaurant}
    getAvailableTimes={getAvailableTimes}
    getAvailableDates={getAvailableDates}
    placeholder="Date of delivery"
    availableDates={availableDates}
    minDate={new Date()}
    grayBackground={false}
    format={'MMMM d, yyyy'}
    className={classNames('flex h-12 rounded pr-2 items-center', {
      'w-full': !isDesktop,
      'justify-between': isDesktop,
    })}
    backgroundColor={true}
  />
);

const TimeDropdown = ({ date, time, allTimes }) => (
  <TimePicker
    fromModal
    labelName="address-time-modal"
    isCart={false}
    selectedDate={date}
    selectedTime={time}
    grayBackground={false}
    key={allTimes}
    className="flex justify-between text-sm border-0 text-primary-black"
    backgroundColor={true}
  />
);

const InputForm = ({
  isDesktop,
  isMobile,
  isTablet,
  userLogin,
  address,
  internalAddress,
  setAddress,
  addressError,
  completeAddressError,
  handleOnChange,
  router,
  store,
  currentUser,
  date,
  dateError,
  time,
  allTimes,
  apply,
  initialFiltersSelected,
  activeRestaurant,
  getAvailableDates,
  getAvailableTimes,
  availableDates,
}) => (
  <div
    className={classNames({
      'flex flex-col justify-center absolute inset-0': true,
      'md:flex md:h-full hidden': isDesktop,
      'customMd:flex h-full hidden md:hidden': isTablet,
      'customMd:hidden md:hidden': isMobile,
    })}>
    <div className="flex flex-col justify-center items-start gap-4 px-8 md:px-16">
      <div
        className={classNames('justify-center items-start gap-4 inline-flex', {
          'w-full': !isDesktop,
        })}>
        <div
          className={classNames('flex-col justify-start items-start gap-8 inline-flex', {
            'w-full': !isDesktop,
          })}>
          <div className="flex-col justify-start items-start gap-4 flex">
            <div
              className={`text-pink-600 ${
                isDesktop ? 'text-[56px]' : isTablet ? 'text-[56px]' : 'text-[38px]'
              } font-inter-semibold`}>
              hungerhub GO
            </div>
            <div
              className={`text-slate-700 ${
                isDesktop ? 'text-[28px]' : isTablet ? 'text-[28px]' : 'text-[20px]'
              } font-inter-medium`}>
              Your on-demand catering marketplace
            </div>
          </div>

          <div
            className={classNames('justify-center items-start', {
              'inline-flex gap-4': isDesktop,
              'flex-col w-full': isMobile,
              'flex-col w-2/3': isTablet,
            })}>
            <div
              className={classNames('justify-start items-start gap-5 flex', {
                'inline-flex': isDesktop,
                'flex-col': !isDesktop,
              })}>
              <div className={classNames({ 'w-full': !isDesktop })}>
                <div
                  className={classNames(
                    'h-12 px-3 py-2 bg-white rounded border border-white justify-start items-center flex mb-1',
                    {
                      'w-[445px]': isDesktop,
                      'w-full': !isDesktop,
                    }
                  )}>
                  <span className="h-4 justify-start items-center flex pl-2">
                    <Image src={MapIcon} className="icon" alt="map-icon" />
                  </span>

                  {userLogin ? (
                    <AddressPicker backgroundColor={true} setAddress={setAddress} />
                  ) : (
                    <AutoComplete
                      addressError={addressError}
                      handleOnChange={handleOnChange}
                      router={router}
                      store={store}
                      userLogin={userLogin}
                      address={address}
                      internalAddress={internalAddress}
                      setAddress={setAddress}
                    />
                  )}
                </div>

                {addressError && <Error />}

                {completeAddressError && (
                  <Error message="Please select address from the address dropdown" />
                )}
              </div>

              <div className={classNames({ 'w-full': !isDesktop })}>
                <div
                  className={classNames(
                    'h-12 px-3 py-2 bg-white rounded border border-white justify-start items-center gap-2.5 flex',
                    {
                      'w-full': !isDesktop,
                      'mb-1': isDesktop,
                    }
                  )}>
                  <DateDropdown
                    internalAddress={internalAddress}
                    date={date}
                    activeRestaurant={activeRestaurant}
                    getAvailableDates={getAvailableDates}
                    getAvailableTimes={getAvailableTimes}
                    availableDates={availableDates}
                    isDesktop={isDesktop}
                  />
                </div>

                {dateError && <Error />}
              </div>

              <div
                className={classNames(
                  'h-12 px-3 py-2 bg-white rounded border border-white justify-start items-center gap-2.5 flex',
                  {
                    'w-full': !isDesktop,
                    'mb-1': isDesktop,
                  }
                )}>
                <TimeDropdown date={date} time={time} allTimes={allTimes} />
              </div>
            </div>

            {isDesktop && (
              <button
                className={classNames({
                  'opacity-50': !initialFiltersSelected,
                  'w-[236px] px-2 py-3 bg-indigo-900 rounded-xl border border-indigo-900 justify-center items-center flex text-white text-base font-inter-normal leading-tight': true,
                })}
                onClick={apply}>
                See available restaurants
              </button>
            )}

            {!isDesktop && (
              <>
                {!currentUser?.attributes && (
                  <button
                    onClick={() => store.userStore.setLoginModal(true)}
                    className={classNames(
                      'text-indigo-500 text-xs font-inter-normal underline leading-7 flex items-center',
                      { 'pl-1 mt-6': !isDesktop }
                    )}>
                    Log in for saved addresses
                  </button>
                )}

                <div className="flex justify-center w-full mt-8">
                  <button
                    className={classNames({
                      'opacity-50': !initialFiltersSelected,
                      'w-full px-2 py-3 bg-indigo-900 rounded-xl border border-indigo-900 justify-center items-center flex text-white text-base font-inter-normal leading-tight mt-5': true,
                    })}
                    onClick={apply}>
                    See available restaurants
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isDesktop && !currentUser?.attributes && (
        <button
          onClick={() => store.userStore.setLoginModal(true)}
          className={classNames(
            'text-indigo-500 text-xs font-inter-normal underline leading-7 flex items-center',
            { 'pl-1 mt-6': !isDesktop }
          )}>
          Log in for saved addresses
        </button>
      )}
    </div>
  </div>
);

export default InputForm;
