import { observer } from 'mobx-react';
import { StoreContext } from 'pages/_app';
import { useContext, useRef, useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { selectStyles, selectStylesDirectory } from 'utils/helpers/styles';
import { toJS } from 'mobx';
import { setCookies } from 'utils/cookies/cookieHandler';
import moment from 'moment-timezone';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import { formattedAddres, parsedAddress } from 'utils/helpers/addresses';
import classNames from 'classnames';

const getAddressState = address => {
  return address
    ? { label: address, value: address }
    : { label: 'Select your address', value: 'Select your address' };
};

const AddressPicker = ({
  fromModal,
  backgroundColor,
  setAddress,
  addressError,
  setAddressError,
  selectedAddress,
  fromRestaurantPage,
}) => {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const router = useRouter();

  const [showAddressDropdown, setShowAddressDropdown] = useState(true);
  const [internalAddress, setInternalAddress] = useState(null);

  let {
    timeOptionsLoading,
    locationAddresses,
    address,
    currentUser,
    employeeID,
    isAdmin,
    isUncateringUser,
    activeEmployees,
  } = userStore;

  const [selectedValue, setSelectedValue] = useState(getAddressState(address));

  const selectRef = useRef();
  const id = 'address-picker';

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSelectedValue(getAddressState(address));
      setInternalAddress(address || '');
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [address]);

  const onMenuOpen = () => {
    setTimeout(() => {
      const { focusedOptionRef } = selectRef.current;
      focusedOptionRef && focusedOptionRef.scrollIntoView();
    }, 1);
  };

  const onChangeAddress = addressValue => {
    if (addressValue.value === 'enter_new_delivery_address') {
      if (!isAdmin) {
        //switch input field
        setShowAddressDropdown(false);
      } else {
        setCookies(employeeID, currentUser);
        window.location.href = `${process?.env?.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK}/delivery-info/create`;
      }

      return; // Prevent further execution for this option
    }

    const foundAddress = locationAddresses?.find(address => address.id === addressValue.value);

    store.userStore?.setAddressID(foundAddress?.id);
    store.userStore?.setAddressLocationID(foundAddress?.locationId);

    store.userStore?.setAddress(addressValue?.label, parsedAddress(foundAddress?.attributes));
    const account = activeEmployees?.find(emp => emp.id == foundAddress?.locationId);
    const isInvoiceEnabled = account?.attributes.invoice_payments == 'enabled';

    if (isInvoiceEnabled)
      store.userStore.setInvoiceDetails({
        invoice_payments: account?.attributes?.invoice_payments,
        account_billing_address: account?.attributes?.account_billing_address,
        account_billing_emails: account?.attributes?.account_billing_emails,
        account_billing_name: account?.attributes?.account_billing_name,
      });
    else
      store.userStore.setInvoiceDetails({
        invoice_payments: '',
        account_billing_address: '',
        account_billing_emails: '',
        account_billing_name: '',
      });

    setAddress({
      address: addressValue?.label,
      completeAddress: parsedAddress(foundAddress?.attributes),
    });

    setSelectedValue({
      label: addressValue.label,
      value: addressValue.value,
    });

    router.replace(
      {
        pathname: router?.pathname,
        query: {
          ...QueryString.parse(router?.query),
          address: addressValue?.label,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );

    store?.userStore?.setLoader(true);
    store.userStore
      .getAvailableDates({
        address: addressValue.label,
        month: moment().month() + 1,
        year: moment().year(),
        current_date_time: moment().format('YYYY-MM-DD HH:mm'),
      })
      .then(() => {
        store?.userStore?.setLoader(false);
      });
  };

  const options =
    locationAddresses && Array.isArray(locationAddresses)
      ? [
          ...locationAddresses.map(address => ({
            label: formattedAddres(address.attributes),
            value: address.id,
          })),
          {
            label: 'Enter new delivery address',
            value: 'enter_new_delivery_address',
          },
        ]
      : [
          {
            label: 'Enter new delivery address',
            value: 'enter_new_delivery_address',
          },
        ];

  const addressValue = {
    label: address,
    value: address,
  };

  const handleOnChange = e => {
    if (addressError) setAddressError(false);
    setAddress({ address: e?.target?.value });
  };

  return (
    <>
      {showAddressDropdown ? (
        <Select
          ref={selectRef}
          menuPosition={fromModal ? 'absolute' : 'fixed'}
          isLoading={timeOptionsLoading}
          onMenuOpen={onMenuOpen}
          isSearchable={false}
          styles={selectStylesDirectory({ backgroundColor })}
          placeholder="Select your address"
          closeMenuOnSelect
          value={selectedValue}
          onChange={onChangeAddress}
          options={options}
          instanceId={id}
        />
      ) : (
        <GoogleAutoComplete
          className={classNames(
            'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md text-primary-black w-full',
            { 'bg-background': fromRestaurantPage }
          )}
          onChange={handleOnChange}
          placeholder="Enter delivery address"
          onBlur={e => {
            const queryDate = router?.query?.date;

            store.userStore.getAvailableDates({
              address: e?.target?.value,
              month: queryDate ? moment(queryDate).month() + 1 : moment().month() + 1,
              year: queryDate ? moment(queryDate).year() : moment().year(),
              current_date_time: moment().format('YYYY-MM-DD HH:mm'),
            });
          }}
          value={selectedAddress}
          onPlaceSelected={place => {
            store.userStore?.getAndSetTimezone(place?.formatted_address);

            const _address = place?.address_components?.reduce(
              (seed, { long_name, short_name, types }) => {
                types.forEach(t => {
                  if (t == 'administrative_area_level_1') {
                    seed[t] = short_name; // Set short_name for province
                  } else {
                    seed[t] = long_name; // Set long_name for other types
                  }
                });
                return seed;
              },
              {}
            );

            setAddress({
              address: place?.formatted_address,
              completeAddress: {
                suite: _address?.subpremise,
                street_number: _address?.street_number,
                street_name: _address?.route,
                city: _address?.locality,
                province: _address?.administrative_area_level_1,
                postal_code: _address?.postal_code,
              },
            });

            store?.userStore?.setAddress(place?.formatted_address, {
              suite: _address?.subpremise,
              street_number: _address?.street_number,
              street_name: _address?.route,
              city: _address?.locality,
              province: _address?.administrative_area_level_1,
              postal_code: _address?.postal_code,
              destination_code: '',
            });
          }}
        />
      )}
    </>
  );
};
export default observer(AddressPicker);
